import { decorate, observable, runInAction, action } from "mobx";
import { getConversation, sendSMS } from "service/messageService";
import _ from "lodash";
import * as mobx from "mobx";
import { getAllGatewayMMS } from "service/gatewayMmsService";
import { getAllGatewaySMS, getAllRetailChat, getConversionMessages } from "service/gatewayService";

class ObservableMessageStore {
  conversation = [];
  pageSize = 10;
  current = 1;
  total = 0;
  sortField = "createdAt";
  sortOrder = "descend";
  search = "";
  selectedRoom = {};
  selectedMessage = undefined;

  //iphone frame ui params
  phoneFrameCurrentMessage = undefined;
  phoneFrameSender = "GUNI SMS";
  phoneFrameSubject = undefined;
  phoneFrameImageUrl = null; // media files
  phoneFrameImageFileUrl = null; // url links
  mediaType = null;
  mediaName = null;
  currentSender = undefined;
  currentReciever = undefined;
  contactHistory = [];

  setCurrentMessage = message => {
    runInAction(() => {
      this.phoneFrameCurrentMessage = message;
    });
  };

  setPhoneSubject = subject => {
    runInAction(() => {
      this.phoneFrameSubject = subject;
    });
  };

  setPhoneSender = sender => {
    runInAction(() => {
      this.phoneFrameSender = sender;
    });
  };

  setCurrentImage = (image, url = false) => {
    runInAction(() => {
      if (url && image) {
        this.phoneFrameImageFileUrl = image;
        this.phoneFrameImageUrl = null;
      } else if (image) {
        this.phoneFrameImageFileUrl = null;
        this.phoneFrameImageUrl = image;
      } else {
        this.phoneFrameImageFileUrl = null;
        this.phoneFrameImageUrl = null;
      }
    });
  };

  setData = (data, value) => {
    runInAction(() => {
      this[data] = value;
    });
  };

  setSelectedMessage = message => {
    runInAction(() => {
      this.selectedMessage = message;
      this.phoneFrameSender = message.sender;
      this.conversation = [];
      this.pageSize = 20;
      this.current = 1;
      this.total = 0;
      (this.currentSender = message.sender), (this.currentReciever = message.receiver);

      this.getConversationHistory({
        receiver: message.receiver,
        sender: message.sender,
        message_id: message?.message_id,
        retailChat: !!message?.retailChat
      });
    });
  };
  loadMore = async () => {
    if (this.current < this.totalPages) {
      this.current = this.current + 1;
      let conversation = await this.getConversationHistory({
        receiver: this.currentReciever,
        sender: this.currentSender
      });
      return conversation;
    }
  };
  setHistory = async message => {
    runInAction(async () => {
      this.pageSize = 50;
      return this.getContactHistory({
        receiver: message.receiver
      });
    });
  };

  custom_sort(a, b) {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  }
  getConversationHistory = async args => {
    // select method between inbox messaging and retail chat messaging
    const method = args?.retailChat ? getAllRetailChat : getConversionMessages;

    // call & save User list response
    const params = {
      page: this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      ...(args && args)
    };

    if (this.search) {
      for (var key of Object.keys(this.search)) {
        if (this.search[key]) {
          params[key] = this.search[key];
        }
      }
    }
    params.limit = this.pageSize;

    const data = await method(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize, totalPages } = data;

        let temp = [...items].reverse();

        temp = temp.concat(mobx.toJS(this.conversation));

        this.conversation = temp;

        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
        this.totalPages = totalPages;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }
    return this.conversation;
  };

  newMessageEvent = messageObj => {
    try {
      runInAction(() => {
        if (this.conversation.length > 0) {
          const data = this.conversation?.[0];
          const { receiver, sender } = data;
          if ([receiver, sender].includes(messageObj?.conversationObj?.sender)) this.conversation.push(messageObj?.conversationObj);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  getContactHistory = async (args, isMms = false, isAdd = false) => {
    // call & save User list response
    const params = {
      page: this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      ...(args && args)
    };

    if (this.search) {
      for (var key of Object.keys(this.search)) {
        if (this.search[key]) {
          params[key] = this.search[key];
        }
      }
    }
    params.limit = 50;

    const data = isMms ? await getAllGatewayMMS(params) : await getAllGatewaySMS(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize, totalPages } = data;
        let temp = [...items];
        if (isAdd) temp = temp.concat(mobx.toJS(this.contactHistory));
        this.contactHistory = temp;
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
        this.totalPages = totalPages;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }

    return this.contactHistory;
  };

  setSearch = search => {
    runInAction(() => {
      this.search = search;
    });
  };

  getTotal = () => {
    return this.total;
  };

  setSelectedRoom = selectedRoom => {
    runInAction(() => {
      this.selectedRoom = selectedRoom;
      this.pageSize = 10;
      this.current = 1;
      this.total = 0;
    });
  };

  setPage = current => {
    runInAction(() => {
      this.current = current || 1;
    });
  };

  /**
   * get all test cases
   * @returns {Promise<[]>}
   */
  // getConversationHistory = async (room, isAdd) => {
  //   // call & save test case list response
  //   const params = {
  //     room,
  //     page: isAdd ? this.current + 1 : this.current,
  //     // sortField: this.sortField,
  //     // sortOrder: this.sortOrder
  //   };
  //   if (this.search.length > 0) params.search = this.search.search;
  //   const messageHistoryResponse = await getConversation(params);
  //   try {
  //     runInAction(() => {
  //       const {
  //         items,
  //         total,
  //         current,
  //         limit: pageSize,
  //       } = messageHistoryResponse;
  //       this.conversation = isAdd
  //         ? _.uniq(
  //             _.concat(
  //               mobx.toJS(this.conversation),
  //               items || messageHistoryResponse
  //             ),
  //             "_id"
  //           )
  //         : _.uniq(items || messageHistoryResponse, "_id");
  //       this.selectedTestCase = undefined;
  //       this.pageSize = pageSize;
  //       this.current = current;
  //       this.total = total;
  //     });
  //   } catch (error) {
  //     runInAction(() => {
  //       this.status = "error";
  //     });
  //   }

  //   return this.conversation;
  // };

  /**
   * Start Test Case
   * @param data
   * @returns {Promise<any>}
   */
  sendSMS = async (data, mode) => {
    return await sendSMS(data, mode);
  };

  addMessage = message => {
    try {
      runInAction(() => {
        if (this.selectedRoom && this.selectedRoom._id && message && message.room) {
          if (this.selectedRoom._id === message.room) {
            const oldLength = this.conversation.length;
            const newMessages = _.mergeById(this.conversation, message, "_id", true);
            const newLength = newMessages.length;
            if (oldLength < newLength) {
              this.conversation = newMessages;
              this.total = newMessages.length;
            }
          }
        }
      });
    } catch (error) {
      console.error("Error", error);
    }
    return this.conversation;
  };
}

decorate(ObservableMessageStore, {
  selectedRoom: observable,
  conversation: observable,
  pageSize: observable,
  current: observable,
  total: observable,
  sortField: observable,
  sortOrder: observable,
  search: observable,
  selectedMessage: observable,
  phoneFrameCurrentMessage: observable,
  phoneFrameSubject: observable,
  phoneFrameSender: observable,
  phoneFrameImageUrl: observable,
  phoneFrameImageFileUrl: observable,
  mediaType: observable,
  mediaName: observable,
  contactHistory: observable
});
export default new ObservableMessageStore();
