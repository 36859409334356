import React from "react";
import moment from "moment";
import { Col, Row, Typography, Tag, Tooltip, Divider } from "antd";
import { dateTimeFormat, dateTimeFormatWithSeconds } from "constant/constant";
import AppButton from "components/AppButton/AppButton";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";

const CampaignDetails = props => {
  const { messageContent = false, campaignData, handleViewContent } = props;
  const groupURL = campaignData?.group && campaignData?.group._id ? campaignData?.group._id : campaignData?.followUpGroup && campaignData?.followUpGroup?._id;

  const getScheduleTypeInfo = data => {
    const getEndInfo = data => {
      if (data.end_date) {
        return `until ${moment(data.end_date).format("DD MMM YYYY")}`;
      }
      return "no end date";
    };

    if (!data.repeat || data.repeat === "one_time") {
      return {
        icon: "calendar-day",
        text: "One Time",
        description: `Scheduled for ${moment(data.scheduleAt).format(dateTimeFormatWithSeconds)}`,
        endInfo: null
      };
    }

    switch (data.repeat) {
      case "daily":
        return {
          icon: "calendar-day",
          text: "Daily",
          description: `Every ${data.every || 1} day(s) starting at ${moment(data.start_date).format(dateTimeFormat)}`,
          endInfo: getEndInfo(data)
        };
      case "weekly":
        return {
          icon: "calendar-week",
          text: "Weekly",
          description: `Every ${data.every || 1} week(s)${data.daysOfWeek ? ` on ${data.daysOfWeek.join(", ")}` : ""} starting at  ${moment(data.start_date).format(dateTimeFormat)}`,
          endInfo: getEndInfo(data)
        };
      case "monthly":
        return {
          icon: "calendar-alt",
          text: "Monthly",
          description: `Every ${data.every || 1} month(s) on day ${data.dayOfMonth || "1"} starting at ${moment(data.start_date).format(dateTimeFormat)}`,
          endInfo: getEndInfo(data)
        };
      default:
        return {
          icon: "calendar",
          text: data.repeat,
          description: "",
          endInfo: getEndInfo(data)
        };
    }
  };

  const scheduleInfo = getScheduleTypeInfo(campaignData);

  return (
    <Row type="flex" align="middle" gutter={[20, 20]} className="pt-3 pt-md-4">
      <Col xl={6} lg={8} md={10} span={24}>
        <Typography.Title level={4} className="fw-bold m-0 text-capitalize fs-6">
          Campaign Name
        </Typography.Title>
      </Col>
      <Col xl={18} lg={16} md={14} span={24}>
        <Typography.Title level={4} ellipsis className="fw-bold m-0 fs-6 text-info-color">
          {campaignData?.name}
        </Typography.Title>
      </Col>
      <Col xl={6} lg={8} md={10} span={24}>
        <Typography.Title level={4} className="fw-bold m-0 text-capitalize fs-6">
          Send Time
        </Typography.Title>
      </Col>
      <Col xl={18} lg={16} md={14} span={24}>
        <Typography.Title level={4} ellipsis className="fw-bold m-0 fs-6 text-info-color">
          {moment(campaignData?.scheduleAt ? campaignData?.scheduleAt : campaignData?.createdAt).format(dateTimeFormatWithSeconds)}
        </Typography.Title>
      </Col>
      <Col xl={6} lg={8} md={10} span={24}>
        <Typography.Title level={4} className="fw-bold m-0 text-capitalize fs-6">
          Schedule Type
        </Typography.Title>
      </Col>
      <Col xl={18} lg={16} md={14} span={24}>
        <Typography.Title level={4} ellipsis className="fw-bold m-0 fs-6 text-info-color">
          {scheduleInfo.text}
        </Typography.Title>
      </Col>
      <Col xl={6} lg={8} md={10} span={24}>
        <Typography.Title level={4} className="fw-bold m-0 text-capitalize fs-6">
          Schedule Details
        </Typography.Title>
      </Col>
      <Col xl={18} lg={16} md={14} span={24}>
        <Typography.Title
          level={4}
          className="fw-bold m-0 fs-6 text-info-color"
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            lineHeight: "1.5"
          }}
        >
          {scheduleInfo.description}
          {scheduleInfo.endInfo && <span className="ms-1">{scheduleInfo.endInfo}</span>}
        </Typography.Title>
      </Col>
      <Col xl={6} lg={8} md={10} span={24}>
        <Typography.Title level={4} className="fw-bold m-0 text-capitalize fs-6">
          Sender
        </Typography.Title>
      </Col>
      <Col xl={18} lg={16} md={14} span={24}>
        <Typography.Title level={4} ellipsis className="fw-bold m-0 fs-6 text-info-color">
          {campaignData?.sender}
        </Typography.Title>
      </Col>
      <Col xl={6} lg={8} md={10} span={24}>
        <Typography.Title level={4} className="fw-bold m-0 text-capitalize fs-6">
          Group {!isEmpty(campaignData?.multigroup) ? "used" : ""}
        </Typography.Title>
      </Col>
      <Col xl={18} lg={16} md={14} span={24}>
        {campaignData?.group ? (
          <Link to={groupURL ? `/group/${groupURL}` : "#"}>
            <Typography.Title level={4} ellipsis className="fw-bold m-0 fs-6 text-info-color text-hover-underline">
              {groupURL ? (campaignData?.group && campaignData?.group._id ? campaignData?.group.name : campaignData?.followUpGroup ? campaignData?.followUpGroup?.name : "Group Removed") : ""}
            </Typography.Title>
          </Link>
        ) : campaignData?.multigroup ? (
          <Typography.Title level={4} ellipsis className="fw-bold m-0 fs-6 text-info-color">
            {campaignData?.multigroup?.length}
          </Typography.Title>
        ) : (
          ""
        )}
      </Col>
      <Col xl={6} lg={8} md={10} span={24}>
        <Typography.Title level={4} className="fw-bold m-0 text-capitalize fs-6">
          Total Recipients
        </Typography.Title>
      </Col>
      <Col xl={18} lg={16} md={14} span={24}>
        <Typography.Title level={4} ellipsis className="fw-bold m-0 fs-6 text-info-color">
          {campaignData?.recipients
            ? campaignData?.recipients
            : campaignData?.group && campaignData?.group._id
            ? campaignData?.group.totalContacts
            : campaignData?.followUpGroup
            ? campaignData?.followUpGroup?.totalContacts
            : "N/A"}
        </Typography.Title>
      </Col>
      {campaignData?.longUrl && (
        <>
          <Col xl={6} lg={8} md={10} span={24}>
            <Typography.Title level={4} className="fw-bold m-0 text-capitalize fs-6">
              Long URL
            </Typography.Title>
          </Col>
          <Col xl={18} lg={16} md={14} span={24}>
            <a href={campaignData?.longUrl} target="_blank" className="text-dark">
              <Typography.Title level={4} ellipsis className="fw-bold m-0 fs-6 text-info-color text-hover-underline">
                {campaignData?.longUrl}
              </Typography.Title>
            </a>
          </Col>
        </>
      )}
      {messageContent && (
        <>
          <Col xl={6} lg={8} md={10} span={24}>
            <Typography.Title level={4} className="fw-bold m-0 text-capitalize fs-6">
              Message Content
            </Typography.Title>
          </Col>
          <Col xl={18} lg={16} md={14} span={24}>
            <AppButton label={"View Content"} light withoutBg onClick={handleViewContent} />
          </Col>
        </>
      )}
      {campaignData?.hasFollowUp && (
        <>
          <Col span={24}>
            <Divider />
          </Col>
          {campaignData?.followUpStatus && (
            <>
              <Col xl={6} lg={8} md={10} span={24}>
                <Typography.Title level={4} className="fw-bold m-0 text-capitalize fs-6">
                  Status
                </Typography.Title>
              </Col>
              <Col xl={18} lg={16} md={14} span={24}>
                {campaignData?.followUpStatus === "failed" ? (
                  <Tooltip
                    placement="left"
                    title={
                      <>
                        Sorry, we couldn't sent this follow-up campaign!
                        <Divider className="my-2" />
                        REASON: {campaignData?.followUpReason}
                      </>
                    }
                    trigger="hover"
                  >
                    <div className="d-flex flex-row align-items-center">
                      <Tag color="red">{campaignData?.followUpStatus?.toUpperCase()}</Tag>
                      <i className="fas fa-info-circle text-danger-color" />
                    </div>
                  </Tooltip>
                ) : (
                  <Tag color={campaignData?.followUpStatus === "queued" ? "volcano" : "cyan"}>{campaignData?.followUpStatus?.toUpperCase()}</Tag>
                )}
              </Col>
            </>
          )}
          <Col xl={6} lg={8} md={10} span={24}>
            <Typography.Title level={4} className="fw-bold m-0 text-capitalize fs-6">
              Follow Up Time
            </Typography.Title>
          </Col>
          <Col xl={18} lg={16} md={14} span={24}>
            <Typography.Title level={4} ellipsis className="fw-bold m-0 fs-6 text-info-color">
              {moment(campaignData?.followUpTime).format(dateTimeFormatWithSeconds)}
            </Typography.Title>
          </Col>
          <Col xl={6} lg={8} md={10} span={24}>
            <Typography.Title level={4} className="fw-bold m-0 text-capitalize fs-6">
              Follow Up Message
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Typography.Title level={4} ellipsis className="fw-bold m-0 fs-6 text-info-color">
              {campaignData?.followUpMessage}
            </Typography.Title>
          </Col>
        </>
      )}
    </Row>
  );
};

export default CampaignDetails;
