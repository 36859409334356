import React from "react";
import { Form, Radio } from "antd";

const FormRadioInput = props => {
  const {
    options,
    errors,
    touched,
    onChange,
    containerClassname,
    radioGroupClassname,
    radioClassname,
    containerProps,
    radioGroupProps,
    radioProps,
    name,
    label,
    disabled,
    value
  } = props;
  return (
    <Form.Item
      label={label}
      validateStatus={errors && touched ? "error" : ""}
      help={errors && touched ? errors : null}
      className={containerClassname}
      {...containerProps}
    >
      <Radio.Group
        name={name}
        value={value}
        onChange={onChange}
        className={radioGroupClassname}
        {...radioGroupProps}
      >
        {options.map(option => (
          <Radio
            key={option.value}
            value={option.value}
            disabled={disabled}
            className={radioClassname}
            {...radioProps}
          >
            {option.label}
          </Radio>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

export default FormRadioInput;
