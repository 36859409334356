import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import styles from "./applayout.module.css";
import logo from "assets/images/common/logo.png";
import logoM from "assets/images/common/logo-m.png";
import MenuBtn from "./MenuBtn";
import { guniHelpUrl } from "constant/constant";
import clsx from "clsx";
// import SaleCountDown from "components/BuyCredits/SaleCountDown";

const AppLayout = props => {
  const { authStore, templateStore, groupStore, workflowStore, contactStore, modalStore, retailChatSideBar = false } = props;
  const [menuIndex, setMenuIndex] = useState(null);
  const [listWidth, setListWidth] = useState(false);
  const [isSidebarOpen, toggleSidebar] = useState(localStorage.getItem("isSidebarOpen") === "true" ? true : false);
  const { user } = authStore;
  const role = user ? user.role : "user";

  useEffect(() => {
    if (window.innerWidth <= 1200) {
      toggleSidebar(false);
    } else {
      toggleSidebar(true);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    if (localStorage.getItem("isSidebarOpen") === "false") {
      toggleSidebar(false);
    } else if (!localStorage.getItem("isSidebarOpen")) {
      toggleSidebar(true);
    } else {
      toggleSidebar(true);
    }
  }, [localStorage.getItem("isSidebarOpen")]);

  useEffect(() => {
    if (menuIndex && MenuOptions[menuIndex - 1].submenu) {
      setListWidth(true);
    } else {
      setListWidth(false);
    }
  }, [menuIndex]);

  let SquareSpaceOptions = [
    {
      id: 1,
      label: "Menu",
      path: "/retail-chat",
      icon: <i className="fas fa-inbox" />,
      submenu: false,
      roles: ["user", "admin", "superAdmin", "guest", "subaccount", "trial"],
      submenu: [
        {
          path: "/retail-chat",
          label: "Chat",
          access: ["sms", "mms"],
          roles: ["user", "admin", "superAdmin", "guest", "subaccount", "trial"]
        }
      ]
    }
  ];

  let NavOptions = [
    {
      id: 1,
      label: "Dashboard",
      path: "/",
      icon: <i className="fas fa-border-all" />,
      submenu: false,
      roles: ["user", "admin", "superAdmin", "guest", "subaccount", "trial"],
      subPaths: ["/dashboard", "/profile", "/security", "/settings"]
    },
    {
      id: 2,
      label: "Conversation",
      path: "/conversation",
      icon: <i className="fas fa-comments" />,
      submenu: false,
      roles: ["user", "admin", "superAdmin", "guest", "trial"],
      access: ["sms", "mms"],
      subPaths: ["/dashboard", "/profile", "/security", "/settings"]
    },
    {
      id: 3,
      label: "Campaign",
      path: "/campaign",
      icon: <i className="fas fa-inbox" />,
      submenu: false,
      roles: ["user", "admin", "superAdmin", "guest", "trial"],
      access: ["sms", "mms"],
      subPaths: ["/mms/campaign/report", "/campaign/create", "/campaign/update", "/campaign/report"]
    },
    {
      id: 4,
      label: "Messaging",
      icon: <i className="fas fa-sms" />,
      submenu: [
        {
          path: "/quick-message",
          label: "New Message",
          access: ["sms", "mms"],
          roles: ["user", "admin", "superAdmin", "guest", "subaccount", "trial"],
          clickAction: () => {
            localStorage.setItem("lastLocation", window.location.pathname);
            modalStore.setData("quickSMSContent", "");
          }
        },
        user?.batch && {
          path: "/batch-message",
          label: "Batch Message",
          access: ["sms"],
          roles: ["user", "admin", "superAdmin", "guest", "subaccount", "trial"]
        },
        role !== "trial" &&
          !user?.quickApproved && {
            path: "/filescheduler",
            label: "File Scheduler",
            access: ["fileScheduler"],
            roles: ["user", "admin", "superAdmin"]
          },
        role !== "trial" &&
          !user?.quickApproved && {
            path: "/email-sms",
            label: "Email SMS",
            access: ["emailSms", "sms", "mms"],
            roles: ["user", "admin", "superAdmin"]
          }
        // {
        //   path: "/inbox-messaging",
        //   label: "Inbox Messaging",
        //   access: ["sms"],
        //   roles: ["user", "admin", "superAdmin", "guest", "subaccount", "trial"]
        // },
        // user?.squareSpaceToken && {
        //   path: "/retail-chat",
        //   label: "Retail Chat",
        //   access: ["sms"],
        //   roles: ["user", "admin", "superAdmin", "guest", "subaccount", "trial"],
        //   clickAction: () => {
        //     window.open("/retailer-chat", "_blank");
        //   }
        // },
        // {
        //   path: "/conversation",
        //   label: "Conversation",
        //   access: ["sms"],
        //   roles: ["user", "admin", "superAdmin", "guest", "subaccount", "trial"]
        // }
      ].filter(Boolean),
      subPaths: ["/scheduler", "/file-scheduler-form", "/email-sms", "/inbox-messaging"],
      access: ["sms", "mms"],
      roles: ["user", "admin", "superAdmin", "guest", "subaccount", "trial"]
    },
    {
      id: 5,
      label: "History",
      path: "/history",
      icon: <i className="fas fa-history" />,
      submenu: false,
      access: ["history", "sms", "mms"],
      roles: ["user", "admin", "superAdmin", "guest", "trial"]
    },
    {
      id: 6,
      label: "Template",
      path: "/template",
      icon: <i className="fas fa-file" />,
      submenu: false,
      access: ["template", "sms", "mms"],
      roles: ["user", "admin", "superAdmin", "guest", "trial"],
      clickAction: () => {
        templateStore.setPage(1);
        templateStore.setSearch("");
        templateStore.setPageSize(10);
      },
      subPaths: ["/template/add", "/template/edit"]
    },
    {
      id: 7,
      label: "Audience",
      icon: <i className="fas fa-users" />,
      access: ["group", "sms", "mms"],
      roles: ["user", "admin", "superAdmin", "guest", "trial", "subaccount"],
      submenu: [
        {
          path: "/group",
          label: "Groups",
          access: ["group"],
          roles: ["user", "admin", "superAdmin", "guest", "trial"],
          access: ["group", "sms", "mms"],
          clickAction: () => {
            groupStore.setPage(1);
            groupStore.setSearch("");
            groupStore.setPageSize(10);
          }
        },
        {
          path: "/opt-out",
          label: "Opt-Out",
          roles: ["user", "admin", "superAdmin", "guest", "trial"],
          access: ["group", "sms", "mms"],
          clickAction: () => {
            contactStore.setPage(1);
            contactStore.setSearch("");
            contactStore.setPageSize(10);
          }
        },
        {
          label: "Medical Contacts",
          path: "/medical-contacts",
          roles: ["superAdmin"]
        }
      ]
    },
    {
      id: 8,
      label: "Buy Credit",
      path: "/buy-credits",
      icon: <i className="fas fa-credit-card" />,
      roles: ["user", "admin", "superAdmin"],
      submenu: false,
      customRoleCheck: user => {
        return user?.role === "subaccount" && user?.deductFromAccount;
      }
    },
    {
      id: 9,
      label: "Sender IDs",
      path: "/sender-ids",
      icon: <i className="fas fa-id-badge" />,
      roles: ["user", "admin", "superAdmin", "guest", "subaccount", "trial"],
      submenu: false
    },
    {
      id: 10,
      label: "Buy Numbers",
      path: "/dedicated-numbers",
      icon: <i className="fas fa-hashtag" />,
      roles: ["user", "admin", "superAdmin"],
      submenu: false
    },
    {
      id: 11,
      label: "Integration",
      path: "/integrations",
      icon: <i className="fas fa-plug" />,
      roles: ["user", "admin", "superAdmin"],
      access: ["integration"],
      submenu: false
    },
    {
      id: 12,
      label: "Automation",
      icon: <i className="fas fa-robot" />,
      access: ["automation"],
      roles: ["user", "admin", "superAdmin", "guest", "trial"],
      submenu: [
        {
          id: 1,
          path: "/automation/contact-form",
          label: "Contact Forms",
          access: ["automation"],
          roles: ["user", "admin", "superAdmin", "guest", "trial"]
        },
        {
          id: 2,
          path: "/automation/workflow",
          label: "Workflow Actions",
          access: ["automation"],
          roles: ["user", "admin", "superAdmin", "guest", "trial"],
          clickAction: () => {
            workflowStore.setPage(1);
            workflowStore.setSearch("");
            workflowStore.setPageSize(10);
          }
        }
      ],
      subPaths: ["/automation/contact-form/add-edit", "/automation/workflow"]
    },
    {
      id: 13,
      label: "Verification",
      path: "/user-verification",
      icon: <i className="fas fa-check-circle" />,
      roles: ["user", "admin", "superAdmin", "guest", "trial"],
      submenu: false
    },
    {
      id: 14,
      label: "Sub-Accounts",
      path: "/sub-accounts",
      icon: <i className="fas fs-5 fa-user-friends" />,
      roles: ["user", "admin", "superAdmin", "guest", "trial"],
      submenu: false
    },
    {
      id: 15,
      label: "Admin Panel",
      path: "/admin/dashboard",
      icon: <i className="fas fs-5 fa-user-cog" />,
      roles: ["superAdmin"],
      subPaths: [
        "/admin/user-template",
        "/admin/device-information",
        "/admin/users",
        "/admin/user-verification",
        "/admin/approval",
        "/admin/shared-numbers",
        "/admin/business-names",
        "/admin/dedicated-numbers",
        "/admin/param",
        "/admin/monitor"
      ],
      submenu: [
        {
          label: "Dashboard",
          path: "/admin/dashboard",
          roles: ["superAdmin"]
        },

        {
          label: "Users",
          path: "/admin/users",
          roles: ["superAdmin"]
        },
        {
          label: "User Verifications",
          path: "/admin/user-verification",
          roles: ["superAdmin"]
        },
        {
          label: "Message Approvals",
          path: "/admin/approval",
          roles: ["superAdmin"]
        },
        {
          label: "Shared Numbers",
          path: "/admin/shared-numbers",
          roles: ["superAdmin"]
        },
        {
          label: "Personal Number",
          path: "/admin/personal-numbers",
          roles: ["superAdmin"]
        },
        {
          label: "Business Names",
          path: "/admin/business-names",
          roles: ["superAdmin"]
        },
        {
          label: "Dedicated Number",
          path: "/admin/dedicated-numbers",
          roles: ["superAdmin"]
        },
        {
          label: "SMS Routes",
          path: "/admin/provider-routes",
          roles: ["superAdmin"]
        },
        {
          label: "MMS Routes",
          path: "/admin/provider-routes/mms",
          roles: ["superAdmin"]
        },
        {
          label: "System Param",
          path: "/admin/param",
          roles: ["superAdmin"]
        },
        {
          label: "Montoring",
          path: "/admin/monitor",
          roles: ["superAdmin"]
        }
        // {
        //   label: "System Usage",
        //   path: "/admin/usage"
        // },
        // {
        //   label: "Deactive Users",
        //   path: "/admin/deactive-users",
        // },
      ]
    }
  ];

  let MenuOptions = retailChatSideBar ? SquareSpaceOptions : NavOptions;

  const handleClick = (index, path) => {
    if (menuIndex === index) {
      setMenuIndex(null);
    } else {
      setMenuIndex(index);
    }

    // special actions upon menu click
    switch (path) {
      case "/user-verification":
        if (!user?.isEmailVerified) modalStore.toggleModal("verifyEmailOtp", true);
        break;
    }
  };

  const filteredMenu = MenuOptions.map(item => {
    if (item.roles.includes(user?.role) || (item.customRoleCheck && item.customRoleCheck(user)) || (item.access && item.access.some(r => user?.access.includes(r)))) {
      return {
        ...item,
        submenu:
          item.submenu && item.submenu.length > 0
            ? item.submenu
                .filter(subItem => {
                  if (subItem?.roles.includes(user?.role) || user?.access.some(r => subItem?.access && subItem?.access.length && subItem?.access.includes(r))) {
                    return subItem;
                  } else {
                    return false;
                  }
                })
                .filter(Boolean)
            : false
      };
    } else {
      return false;
    }
  }).filter(Boolean);

  let topMargin = "0.4rem";

  if (menuIndex) {
    let number = 1;
    number = filteredMenu.find(item => item === filteredMenu[menuIndex])?.submenu?.length;
    if (number) topMargin = number * 33.4 + 6.4 + "px";
  }

  return (
    <>
      <div>
        {/* <SaleCountDown /> */}
        <div className={`d-flex position-relative ${styles.wrapper}`}>
          <nav className={`${isSidebarOpen ? styles.active : ""} ${styles.sidebar}`}>
            <div
              className={`${styles.toggler} ${isSidebarOpen ? "" : styles.active}`}
              onClick={() => {
                toggleSidebar(!isSidebarOpen);
                localStorage.setItem("isSidebarOpen", !isSidebarOpen);
              }}
            >
              <i className={`fas fa-bars fs-5`} />
            </div>
            <div className={`mb-3 ${styles.logo}`}>
              <Link to="/" className={isSidebarOpen ? "" : "d-none"}>
                <img src={isSidebarOpen ? logo : logoM} alt="guni" height={45} />
              </Link>
            </div>
            <div className={`${listWidth || isSidebarOpen ? styles.active : ""} ${styles.list}`}>
              <ul className={`list-unstyled ${styles.listItems} ${isSidebarOpen ? "" : styles.active}`}>
                {filteredMenu.map((item, index) => {
                  return (
                    <MenuBtn
                      key={index}
                      label={item.label}
                      icon={item.icon}
                      submenu={item.submenu}
                      path={item.path}
                      action={item.clickAction}
                      subPaths={item.subPaths}
                      activeId={menuIndex}
                      active={isSidebarOpen}
                      itemIndex={index}
                      topMargin={topMargin}
                      onClick={() => handleClick(index, item.path)}
                    />
                  );
                })}
              </ul>
            </div>
            <div>
              <ul className={`list-unstyled ${styles.listItems} ${isSidebarOpen ? "" : styles.active}`}>
                <MenuBtn
                  key={"support"}
                  label={"Documents"}
                  icon={<i className="fas fa-book fs-5 mx-3" />}
                  onClick={() => window.open(guniHelpUrl, "_blank")}
                  path={guniHelpUrl}
                  target={"_blank"}
                  active={isSidebarOpen}
                  submenu={false}
                  itemIndex={99}
                  topMargin={topMargin}
                />
                {/* <span className="text-white">
                <i className="fas fa-book fs-5 mx-3"></i> Documents
              </span> */}
              </ul>
            </div>
          </nav>
          <div onClick={() => setMenuIndex(null)} style={{ width: "100%", overflow: "auto" }}>
            {props.children}
          </div>
        </div>
      </div>
    </>
  );
};

export default inject(stores => ({
  authStore: stores.store.authStore,
  templateStore: stores.store.templateStore,
  groupStore: stores.store.groupStore,
  gatewayBulkStore: stores.store.gatewayBulkStore,
  gatewayMmsStore: stores.store.gatewayMmsStore,
  adminGatewayStore: stores.store.adminGatewayStore,
  workflowStore: stores.store.workflowStore,
  contactStore: stores.store.contactStore,
  modalStore: stores.store.modalStore
}))(observer(AppLayout));
