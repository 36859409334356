import React from "react";
import { observer, inject } from "mobx-react";
import { Modal, Popover, Row, Typography, Col } from "antd";
import Modals from "assets/css/modals.module.css";

import alert from "assets/images/unicode/infoicon.png";
import AppButton from "components/AppButton/AppButton";
import UnicodeRemove from "./UnicodeRemove";
import { guniHelpUrl } from "constant/constant";

const UnicodeNotification = props => {
  const { modalStore, message, onChange, setAllowUnicode } = props;

  const showUnicodeRemoveModal = () => {
    modalStore.toggleModal("showUnicodeRemoveModal", true);
    modalStore.toggleModal("showUnicodeNotificationModal", false);
  };

  const handleUnicodeChange = newMessage => {
    // Update the message in your state or store
    // This is a placeholder - you'll need to implement the actual update logic
    console.log("Updated message:", newMessage);
    // Close the modal after updating
    modalStore.toggleModal("showUnicodeRemoveModal", false);
  };

  return (
    <>
      <Modal
        style={{ borderRadius: "20px" }}
        title={
          <div className="text-center">
            <h4 className="pt-5 fw-bold">Unicode Notification</h4>
            <img src={alert} alt="alert" className={Modals.popupImg} />
          </div>
        }
        closeIcon={
          <div className={`btn ${Modals.modalCloseBtn}`}>
            <i className="fas fa-times"></i>
          </div>
        }
        visible={modalStore.showUnicodeNotificationModal}
        onCancel={() => {
          modalStore.toggleModal("showUnicodeNotificationModal", false);
          setAllowUnicode(true);
        }}
        maxWidth={800}
        footer={null}
        maskStyle={{
          background: "linear-gradient(135deg, #00000050, #107c9850)"
        }}
        bodyStyle={{
          paddingTop: 0
        }}
        zIndex={1070}
        centered={true}
      >
        <Row type="flex" justify="center">
          <Col span={20}>
            <h5 className="text-center">
              Unicode Char Has Been Identified
              <br />
              In Your SMS Message
            </h5>
          </Col>
          <Col span={24}>
            <Row type="flex" justify="center" className="flex-row">
              <AppButton
                label="Ignore"
                onClick={() => {
                  modalStore.toggleModal("showUnicodeNotificationModal", false);
                  setAllowUnicode(true);
                }}
                light
                size="default"
                withoutBg
                className="mx-2"
              />
              <Popover
                placement="bottom"
                content={
                  <Row type="flex" justify="center" className="p-2" style={{ width: "450px" }}>
                    <Col span={20} className="text-center">
                      <Typography.Title level={4}>Unicode Char Has Been Identified In Your Message</Typography.Title>
                    </Col>
                    <Col span={20} className="text-center">
                      <Typography.Text>
                        A Unicode char will cost you more Credits.
                        <br />
                        Unicode might have appeared because of Copying/Pasting of Message Content.
                      </Typography.Text>
                    </Col>
                    <Col span={20} className="text-center mt-2">
                      <a target="_blank" href={guniHelpUrl + "/sms-characters-count"}>
                        <AppButton label="More Details" light size="default" />
                      </a>
                    </Col>
                  </Row>
                }
                trigger={["click", "hover"]}
                zIndex={2000}
              >
                {/* <AppButton label="Know More" light withoutBg className="mx-2" size="default" /> */}
              </Popover>
              <AppButton label="Remove Unicode" onClick={showUnicodeRemoveModal} light size="default " />
            </Row>
          </Col>
        </Row>
      </Modal>
      <UnicodeRemove message={message} onChange={onChange} />
    </>
  );
};

export default inject(stores => ({
  modalStore: stores.store.modalStore
}))(observer(UnicodeNotification));
