import React, { useEffect, useState } from "react";
import { Modal, Typography, Row, Col, Form, Card, Divider, Radio, TimePicker, DatePicker, Tabs, Tooltip, Select, Checkbox } from "antd";
import moment from "moment";
import { Formik } from "formik";
import * as Yup from "yup";
import AppButton from "components/AppButton/AppButton";
import FormInput from "components/FormItems/FormInput";
import FormSelectInput from "components/FormItems/FormSelectInput";
import styles from "assets/css/modals.module.css";
import { dateTimeFormat } from "constant/constant";
import { inject, observer } from "mobx-react";
import AppDatePicker from "components/AppPickers/AppDatePicker";
import AppTimePicker from "components/AppPickers/AppTimePicker";
import FormRadioInput from "components/FormItems/FormRadioInput";

const { TabPane } = Tabs;

const validationSchema = Yup.object().shape({
  repeat: Yup.string()
    .oneOf(["daily", "weekly", "monthly", "one_time"])
    .required("Repeat is required"),

  repeatInterval: Yup.number()
    .when("repeat", {
      is: repeat => repeat !== "one_time",
      then: Yup.number()
        .required("Repeat interval is required")
        .min(1, "Must be at least 1")
        .when("repeat", {
          is: "daily",
          then: Yup.number().max(365, "Cannot exceed 365 days")
        })
        .when("repeat", {
          is: "weekly",
          then: Yup.number().max(52, "Cannot exceed 52 weeks")
        })
        .when("repeat", {
          is: "monthly",
          then: Yup.number().max(12, "Cannot exceed 12 months")
        })
    })
    .nullable(),

  startDate: Yup.date().when("repeat", {
    is: repeat => repeat !== "one_time",
    then: Yup.date().required("Start date is required")
  }),

  endDate: Yup.date().when("repeat", {
    is: repeat => repeat !== "one_time",
    then: Yup.date()
      .required("End date is required")
      .min(Yup.ref("startDate"), "End date must be after start date")
  }),

  // Weekly specific validation
  daysOfWeek: Yup.array().when("repeat", {
    is: "weekly",
    then: Yup.array()
      .min(1, "Select at least one day")
      .required("Select at least one day of the week")
      .nullable()
  }),

  // Monthly specific validation
  monthlyType: Yup.string().when("repeat", {
    is: "monthly",
    then: Yup.string()
      .oneOf(["onDate", "pattern"])
      .required("Select monthly pattern type")
      .nullable()
  }),

  dayOfMonth: Yup.number()
    .nullable()
    .when(["repeat", "monthlyType"], {
      is: (repeat, monthlyType) => repeat === "monthly" && monthlyType === "onDate",
      then: Yup.number()
        .required("Day of month is required")
        .min(1, "Must be at least 1")
        .max(31, "Cannot exceed 31")
        .nullable()
    }),

  monthWeek: Yup.string()
    .when(["repeat", "monthlyType"], {
      is: (repeat, monthlyType) => repeat === "monthly" && monthlyType === "pattern",
      then: Yup.string()
        .oneOf(["first", "second", "third", "fourth", "last"])
        .required("Select week of month")
    })
    .nullable(),

  monthWeekDay: Yup.string()
    .when(["repeat", "monthlyType"], {
      is: (repeat, monthlyType) => repeat === "monthly" && monthlyType === "pattern",
      then: Yup.string()
        .oneOf(["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"])
        .required("Select day of week")
    })
    .nullable(),

  scheduleAt: Yup.date()
    .when("repeat", {
      is: "one_time",
      then: Yup.date().required("Schedule time is required")
    })
    .nullable()
});

const CampaignSchedule = props => {
  const { setFieldValue, values, error, touched, scheduler = false, modalStore } = props;
  const [activeTab, setActiveTab] = useState("non-repetitive");
  const [sendType, setSendType] = useState("now");

  let types = [{ title: "Now", value: "now" }, ...(scheduler ? [{ title: "Later", value: "schedule" }] : [])];

  const handleScheduleChange = value => {
    setSendType(value);
    if (value === "now") {
      resetFormValues(setFieldValue);
    } else {
      setFieldValue("sendType", "schedule");
      modalStore.toggleModal("showCampaginScheduler", true);
    }
  };

  const resetFormValues = setFieldValue => {
    setFieldValue("scheduleAt", null);
    setFieldValue("repeat", null);
    setFieldValue("repeatInterval", null);
    setFieldValue("repeatUnit", null);
    setFieldValue("daysOfWeek", null);
    setFieldValue("dayOfMonth", null);
    setFieldValue("monthWeek", null);
    setFieldValue("monthWeekDay", null);
    setFieldValue("endDate", null);
  };

  const handleTabChange = key => {
    setActiveTab(key);
    setFieldValue("repetitive", key === "repetitive");
    if (key === "non-repetitive") {
      // Reset recurring-specific values when switching to one-time
      setFieldValue("repeat", "one_time");
      setFieldValue("repeatInterval", null);
      setFieldValue("repeatUnit", null);
      setFieldValue("daysOfWeek", null);
      setFieldValue("endDate", null);
    }
  };

  const handleModalCancel = () => {
    setSendType("now");
    resetFormValues(setFieldValue);
    modalStore.toggleModal("showCampaginScheduler", false);
  };

  const handleSubmit = async (formValues, { setSubmitting }) => {
    try {
      let scheduleData = {};

      if (activeTab === "non-repetitive") {
        scheduleData = {
          scheduleAt: formValues?.scheduleAt,
          repeat: "one_time"
        };
      } else {
        // For recurring schedules
        scheduleData = {
          repeat: formValues?.repeat,
          repeatInterval: formValues?.repeatInterval,
          repeatUnit: formValues?.repeat,
          daysOfWeek: formValues.daysOfWeek?.length ? formValues.daysOfWeek.map(day => day.toLowerCase()) : null,
          dayOfMonth: formValues?.dayOfMonth || undefined,
          weekDayOfMonth: formValues.monthlyType === "pattern" ? `${formValues.monthWeek}:${formValues.monthWeekDay}` : undefined,
          start_date: formValues?.startDate,
          end_date: formValues?.endDate
        };
      }

      // Remove null/undefined values
      Object.keys(scheduleData).forEach(key => (scheduleData[key] === null || scheduleData[key] === undefined) && delete scheduleData[key]);

      // Update parent form values with cleaned data
      Object.keys(scheduleData).forEach(key => {
        setFieldValue(key, scheduleData[key]);
      });

      modalStore.toggleModal("showCampaginScheduler", false);
      modalStore.notify({
        message: "Campaign scheduled successfully",
        type: "success"
      });
    } catch (error) {
      modalStore.notify({
        message: "Failed to schedule campaign",
        type: "error"
      });
    } finally {
      setSubmitting(false);
    }
  };

  const getScheduleDescription = formValues => {
    // For immediate sending
    if (formValues.sendType === "now") {
      return "Campaign will send immediately";
    }

    // For one-time schedule
    if (!formValues.repetitive) {
      if (!formValues.scheduleAt) {
        return "Select when you'd like to send this campaign";
      }
      return `Campaign will send once on ${moment(formValues.scheduleAt).format("MMMM D, YYYY [at] h:mm A")}`;
    }

    // For recurring schedules
    if (!formValues.repeat || !formValues.startDate) {
      return "Select schedule settings";
    }

    let description = "Campaign will send ";

    // Get frequency description
    switch (formValues.repeat) {
      case "daily":
        const interval = formValues.repeatInterval || 1;
        description += interval === 1 ? "every day" : `every ${interval} days`;
        break;

      case "weekly":
        const weekInterval = formValues.repeatInterval || 1;
        if (formValues.daysOfWeek?.length) {
          const days = formValues?.daysOfWeek?.map(day => day.charAt(0).toUpperCase() + day?.slice(1).toLowerCase());

          if (weekInterval === 1) {
            if (days.length === 7) {
              description += "every day";
            } else {
              description += `every ${days.join(", ")}`;
            }
          } else {
            if (days.length === 7) {
              description += `every ${weekInterval} weeks`;
            } else {
              description += `every ${weekInterval} weeks on ${days.join(", ")}`;
            }
          }
        } else {
          description += weekInterval === 1 ? "weekly" : `every ${weekInterval} weeks`;
        }
        break;

      case "monthly":
        const monthInterval = formValues.repeatInterval || 1;

        if (formValues.monthlyType === "onDate" && formValues.dayOfMonth) {
          description += monthInterval === 1 ? `on day ${formValues.dayOfMonth} of every month` : `on day ${formValues.dayOfMonth} every ${monthInterval} months`;
        } else if (formValues.monthlyType === "pattern" && formValues.monthWeek && formValues.monthWeekDay) {
          const weekDay = formValues.monthWeekDay.charAt(0).toUpperCase() + formValues.monthWeekDay.slice(1);
          const weekNum = formValues.monthWeek.charAt(0).toUpperCase() + formValues.monthWeek.slice(1);

          description += monthInterval === 1 ? `on the ${weekNum} ${weekDay} of every month` : `on the ${weekNum} ${weekDay} every ${monthInterval} months`;
        } else {
          description += monthInterval === 1 ? "monthly" : `every ${monthInterval} months`;
        }
        break;

      default:
        return "Select schedule settings";
    }

    // Add date range
    if (formValues.startDate) {
      description += `\nStarts: ${moment(formValues.startDate).format("MMMM D, YYYY")}`;
    }

    if (formValues.endDate) {
      description += `\nEnds: ${moment(formValues.endDate).format("MMMM D, YYYY")}`;
    } else {
      description += "\nNo end date set";
    }

    return description;
  };

  useEffect(() => {
    let campaign = JSON.parse(localStorage?.getItem("campaign"));

    if (campaign?.repeat !== "one_time") {
      setSendType("schedule");
    }
  }, []);

  return (
    <>
      <Radio.Group onChange={e => handleScheduleChange(e.target.value)} value={sendType} className="d-flex gap-3 campaign-radio">
        <Row type="flex" gutter={[10, 10]}>
          {types.map((type, i) => (
            <Col key={i}>
              <Radio.Button className={`${sendType === type.value ? "border-info-color" : "border"} rounded-xl`} value={type.value}>
                <Row type="flex" align="middle">
                  <Col span={2}>{sendType === type.value ? <i className="fas fa-check-circle text-info-color" /> : <i className="far fa-circle text-info-color" />}</Col>
                  <Col span={20}>
                    <Typography.Title level={4} className="fs-6 mb-0 ms-3">
                      {type.title}
                    </Typography.Title>
                  </Col>
                </Row>
              </Radio.Button>
            </Col>
          ))}
        </Row>
      </Radio.Group>

      <Modal
        destroyOnClose
        centered
        title={
          <div className="text-center">
            <h4 className="pt-3 fw-bold">Schedule Campaign</h4>
          </div>
        }
        visible={modalStore.showCampaginScheduler}
        onCancel={handleModalCancel}
        footer={null}
        closeIcon={
          <div className={`btn ${styles.modalCloseBtn}`}>
            <i className="fas fa-times"></i>
          </div>
        }
        width={800}
        maskStyle={{
          background: "linear-gradient(135deg, #00000050, #107c9850)"
        }}
      >
        <Formik
          initialValues={{
            scheduleAt: values.scheduleAt,
            repeat: values?.repeat || "",
            endDate: values?.endDate,
            startTime: values?.startTime,
            dayOfMonth: values?.dayOfMonth
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {({ handleSubmit, setFieldValue, isSubmitting, errors, touched, values: formValues }) => {
            console.log(errors);

            return (
              <Form onSubmit={handleSubmit}>
                <Tabs activeKey={activeTab} onChange={handleTabChange}>
                  <TabPane tab="One time" key="non-repetitive">
                    <Card bordered className="rounded shadow-sm mb-4">
                      <div className="p-4">
                        <Typography.Title level={4} className="d-flex align-items-center mb-4">
                          <i className="fas fa-calendar-day text-info-color me-2" />
                          Choose Date and Time
                        </Typography.Title>

                        <AppDatePicker
                          showTime={{
                            format: "h:mm A",
                            minuteStep: 15,
                            use12Hours: true
                          }}
                          value={formValues.scheduleAt ? moment(formValues.scheduleAt) : undefined}
                          onChange={time => setFieldValue("scheduleAt", time ? time.toISOString() : null)}
                          errors={errors.scheduleAt}
                          touched={touched.scheduleAt}
                          size="large"
                          placeholder="Select when to send"
                          pickerProps={{
                            format: dateTimeFormat,
                            minuteStep: 15,
                            use12Hours: true,
                            showTime: true
                          }}
                          containerClassname="mb-5 w-100"
                          pickerClassname="w-100"
                        />
                        {errors.scheduleAt && touched.scheduleAt && <div className="text-danger mb-2">{errors.scheduleAt}</div>}

                        <Typography.Text type="secondary" className="d-block mt-3">
                          <i className="fas fa-info-circle me-2" />
                          {getScheduleDescription({ ...formValues, repetitive: false })}
                        </Typography.Text>
                      </div>
                    </Card>
                  </TabPane>

                  <TabPane tab="Re-occurring" key="repetitive">
                    <Card bordered className="rounded shadow-sm mb-4">
                      <div className="p-4">
                        <Typography.Title level={4} className="d-flex align-items-center mb-4">
                          <i className="fas fa-calendar-alt text-info-color me-2" />
                          Configure Schedule
                        </Typography.Title>

                        {/* Start Date */}
                        <div className="mb-4">
                          <Typography.Text strong className="d-block mb-2">
                            Start Date
                          </Typography.Text>
                          <AppDatePicker
                            showTime={{
                              format: "h:mm A",
                              minuteStep: 15,
                              use12Hours: true
                            }}
                            value={formValues.startDate ? moment(formValues.startDate) : undefined}
                            onChange={date => setFieldValue("startDate", date ? date.toISOString() : null)}
                            errors={errors.startDate}
                            touched={touched.startDate}
                            size="large"
                            placeholder="Select start date and time"
                            pickerProps={{
                              format: dateTimeFormat,
                              minuteStep: 15,
                              use12Hours: true,
                              showTime: true
                            }}
                            containerClassname="w-100"
                            pickerClassname="w-100"
                          />
                        </div>
                        <div className="mb-4">
                          <Typography.Text strong className="d-block mb-2">
                            Repeat
                          </Typography.Text>
                          <FormRadioInput
                            name="repeat"
                            value={formValues.repeat}
                            onChange={e => {
                              const selectedRepeat = e.target.value;
                              setFieldValue("repeat", selectedRepeat);

                              // Reset related fields
                              setFieldValue("daysOfWeek", []);
                              setFieldValue("monthlyType", "onDate");
                              setFieldValue("monthDay", null);
                              setFieldValue("monthWeek", "first");
                              setFieldValue("monthWeekDay", "monday");

                              // Reset repeatInterval if it exceeds the new max
                              const currentInterval = formValues.repeatInterval || 1;
                              const newMax = selectedRepeat === "daily" ? 365 : selectedRepeat === "weekly" ? 52 : 12;
                              if (currentInterval > newMax) {
                                setFieldValue("repeatInterval", newMax);
                              }
                            }}
                            options={[
                              { label: "Daily", value: "daily" },
                              { label: "Weekly", value: "weekly" },
                              { label: "Monthly", value: "monthly" }
                            ]}
                            containerClassname="d-flex flex-column gap-2"
                          />
                        </div>
                        {/* Repeat Every section */}
                        <div className="mb-4">
                          <Row type="flex" align="middle" gutter={16} className="mb-2">
                            <Col flex="none">
                              <Typography.Text>Repeat every</Typography.Text>
                            </Col>
                            <Col flex="none">
                              <FormInput
                                type="number"
                                value={formValues.repeatInterval}
                                onChange={e => {
                                  const value = Math.min(parseInt(e.target.value) || 0, formValues.repeat === "daily" ? 365 : formValues.repeat === "weekly" ? 52 : 12);
                                  setFieldValue("repeatInterval", value || "");
                                }}
                                style={{
                                  width: "120px",
                                  height: "40px",
                                  margin: "0 8px"
                                }}
                                min={1}
                                max={formValues.repeat === "daily" ? 365 : formValues.repeat === "weekly" ? 52 : 12}
                                error={errors.repeatInterval}
                                touched={touched.repeatInterval}
                              />
                            </Col>
                            <Col flex="none">
                              <FormSelectInput
                                value={formValues?.repeat}
                                onChange={value => {
                                  // Update both repeat and repeatUnit when dropdown changes
                                  setFieldValue("repeat", value);

                                  // Reset related fields based on new selection
                                  setFieldValue("daysOfWeek", []);
                                  setFieldValue("monthlyType", "onDate");
                                  setFieldValue("monthDay", null);
                                  setFieldValue("monthWeek", "first");
                                  setFieldValue("monthWeekDay", "monday");

                                  // Reset repeatInterval if it exceeds the new max
                                  const currentInterval = formValues.repeatInterval || 1;
                                  const newMax = value === "daily" ? 365 : value === "weekly" ? 52 : 12;
                                  if (currentInterval > newMax) {
                                    setFieldValue("repeatInterval", newMax);
                                  }
                                }}
                                options={[
                                  { label: "Days", value: "daily" },
                                  { label: "Weeks", value: "weekly" },
                                  { label: "Months", value: "monthly" }
                                ]}
                                style={{
                                  width: "120px",
                                  height: "40px"
                                }}
                              />
                            </Col>
                          </Row>
                        </div>

                        {formValues.repeat === "weekly" && (
                          <div className="mb-4">
                            <Typography.Text strong className="d-block mb-4">
                              On these days
                            </Typography.Text>
                            <FormSelectInput
                              value={formValues.daysOfWeek || []}
                              onChange={value => setFieldValue("daysOfWeek", value)}
                              options={[
                                { label: "Monday", value: "Monday" },
                                { label: "Tuesday", value: "Tuesday" },
                                { label: "Wednesday", value: "Wednesday" },
                                { label: "Thursday", value: "Thursday" },
                                { label: "Friday", value: "Friday" },
                                { label: "Saturday", value: "Saturday" },
                                { label: "Sunday", value: "Sunday" }
                              ]}
                              mode="multiple" // Enable multiple selection
                              placeholder="Select days of the week"
                              style={{ width: "100%" }}
                              error={errors.daysOfWeek}
                              touched={touched.daysOfWeek}
                            />
                          </div>
                        )}

                        {formValues.repeat === "monthly" && (
                          <div className="mb-4">
                            <div className="d-flex align-items-center justify-content-center mb-4">
                              <Divider className="flex-grow-1" style={{ minWidth: "100px" }} />
                              <Typography.Text strong className="mx-4">
                                On
                              </Typography.Text>
                              <Divider className="flex-grow-1" style={{ minWidth: "100px" }} />
                            </div>
                            <Radio.Group name="monthlyType" value={formValues.monthlyType} onChange={e => setFieldValue("monthlyType", e.target.value)} className="d-flex flex-column gap-4">
                              <div className="d-flex align-items-center gap-3">
                                <Radio value="onDate" />
                                <Row type="flex" align="middle" gutter={16} className="flex-nowrap w-100">
                                  <Col flex="none" className="d-flex align-items-center">
                                    On day
                                  </Col>
                                  <Col flex="none">
                                    <div style={{ transform: "translateY(10px)" }}>
                                      <FormInput
                                        type="number"
                                        value={formValues?.dayOfMonth}
                                        onChange={e => {
                                          const value = Math.min(parseInt(e.target.value) || 0, 31);
                                          setFieldValue("dayOfMonth", value || "");
                                          setFieldValue("monthWeek", null);
                                          setFieldValue("monthWeekDay", null);
                                        }}
                                        error={errors?.dayOfMonth}
                                        touched={touched?.dayOfMonth}
                                        style={{
                                          width: "80px",
                                          height: "40px",
                                          textAlign: "center"
                                        }}
                                        min={1}
                                        max={31}
                                        disabled={formValues.monthlyType !== "onDate"}
                                      />
                                    </div>
                                  </Col>
                                  <Col flex="none" className="d-flex align-items-center">
                                    of the month
                                  </Col>
                                </Row>
                              </div>

                              <div className="d-flex align-items-center gap-3">
                                <Radio value="pattern" className="mt-2" />
                                <Row type="flex" align="middle" gutter={8} className="flex-nowrap">
                                  <Col flex="none" className="d-flex align-items-center">
                                    The
                                  </Col>
                                  <Col flex="none" className="d-flex align-items-center">
                                    <FormSelectInput
                                      value={formValues?.monthWeek}
                                      onChange={value => setFieldValue("monthWeek", value)}
                                      error={errors?.monthWeek}
                                      touched={touched?.monthWeek}
                                      options={[
                                        { label: "First", value: "first" },
                                        { label: "Second", value: "second" },
                                        { label: "Third", value: "third" },
                                        { label: "Fourth", value: "fourth" },
                                        { label: "Last", value: "last" }
                                      ]}
                                      style={{
                                        width: "120px",
                                        height: "40px"
                                      }}
                                      disabled={formValues.monthlyType !== "pattern"}
                                    />
                                  </Col>
                                  <Col flex="none" className="d-flex align-items-center">
                                    <FormSelectInput
                                      value={formValues.monthWeekDay || "monday"}
                                      error={errors?.monthWeekDay}
                                      touched={touched?.monthWeekDay}
                                      onChange={value => setFieldValue("monthWeekDay", value)}
                                      options={[
                                        { label: "Monday", value: "monday" },
                                        { label: "Tuesday", value: "tuesday" },
                                        { label: "Wednesday", value: "wednesday" },
                                        { label: "Thursday", value: "thursday" },
                                        { label: "Friday", value: "friday" },
                                        { label: "Saturday", value: "saturday" },
                                        { label: "Sunday", value: "sunday" }
                                      ]}
                                      style={{
                                        width: "120px",
                                        height: "40px"
                                      }}
                                      disabled={formValues.monthlyType !== "pattern"}
                                    />
                                  </Col>
                                  <Col flex="none" className="d-flex align-items-center">
                                    of the month
                                  </Col>
                                </Row>
                              </div>
                            </Radio.Group>
                          </div>
                        )}
                        {/* End Date */}
                        <div className="mb-4">
                          <Typography.Text strong className="d-block mb-2">
                            End
                          </Typography.Text>
                          <AppDatePicker
                            value={formValues.endDate ? moment(formValues.endDate) : undefined}
                            onChange={date => setFieldValue("endDate", date ? date.toISOString() : null)}
                            errors={errors.endDate}
                            touched={touched.endDate}
                            size="large"
                            placeholder="Select end date"
                            pickerProps={{
                              format: "DD/MM/YYYY",
                              disabledDate: current => current && current < moment(formValues.startDate).startOf("day")
                            }}
                            containerClassname="w-100"
                            pickerClassname="w-100"
                          />
                        </div>

                        <Typography.Text type="secondary" className="d-block mt-3">
                          <i className="fas fa-info-circle me-2" />
                          {getScheduleDescription({ ...formValues, repetitive: true })}
                        </Typography.Text>
                      </div>
                    </Card>
                  </TabPane>
                </Tabs>
                <div className="text-center">
                  <AppButton type="submit" label="Save" light block disabled={isSubmitting} />
                  {Object.keys(errors).length > 0 && <div className="text-danger mt-2">Please fill in all required fields correctly</div>}
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>

      {error && touched && <Typography.Text className="text-danger-color fs-6">{error}</Typography.Text>}
    </>
  );
};

export default inject(stores => ({
  modalStore: stores.store.modalStore
}))(observer(CampaignSchedule));
