import React, { useEffect, useState } from "react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { Popover } from "antd";
import AppButton from "components/AppButton/AppButton";
import { inject, observer } from "mobx-react";

const EmojiPicker = props => {
  const { onEmojiSelect, btnProps = {}, size = "large", btnClass = "", placement, modalStore } = props;
  const [showPopover, setShowPopover] = useState(false);

  useEffect(() => {
    if (modalStore.showUnicodeNotificationModal) {
      setShowPopover(false);
    }
  }, [modalStore.showUnicodeNotificationModal]);

  const handleClick = () => setShowPopover(!showPopover);
  return (
    <Popover
      content={<Picker data={data} onEmojiSelect={onEmojiSelect} emojiSize={18} theme="light" previewPosition="none" />}
      onVisibleChange={handleClick}
      visible={showPopover}
      trigger="click"
      placement={placement || "topRight"}
      zIndex={1055}
    >
      <AppButton className={`border-0 bg-light ${btnClass}`} prefixIcon={<i className="far fa-smile text-info-color"></i>} onClick={handleClick} size={size} {...btnProps} />
    </Popover>
  );
};

export default inject(stores => ({
  modalStore: stores.store.modalStore
}))(observer(EmojiPicker));
